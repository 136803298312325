import type { FC } from "react";

import Typography from "@PRNDcompany/typography";

import type { ChoiceActionsProps, DefaultActionsProps, ReactionActionsProps } from "./PopupActions";
import { ChoiceActions, ReactionActions, DefaultActions } from "./PopupActions";
import type { DimmerProps } from "./PopupDimmer";
import PopupDimmer from "./PopupDimmer";
import { buttonAreaStyle, contentAreaStyle, wrapperStyle } from "./styles";

type PopupSize = 400 | 560 | 800 | "mobile";

type VariantProps =
  | ({ variant?: "default"; emoji?: never } & DefaultActionsProps)
  | ({ variant?: "reaction"; emoji: string } & ReactionActionsProps)
  | ({ variant?: "choice"; emoji?: never } & ChoiceActionsProps);
// TODO-ui/popup: option variant
// | { variant: "option"; emoji?: never };

type PopupProps = { title: string; size: PopupSize } & DimmerProps & VariantProps;

const Popup: FC<PopupProps> = ({
  variant = "default",
  size,
  open,
  emoji,
  title,
  children,
  confirmButtonProps,
  cancelButtonProps,
  closeButtonProps,
}) => {
  return (
    <PopupDimmer open={open}>
      <div css={[wrapperStyle, { width: size === "mobile" ? "100%" : `${size / 16}rem` }]}>
        <div css={contentAreaStyle}>
          {variant === "reaction" && (
            <Typography variant="Headline_1" customCSS={{ fontSize: "4.5rem", lineHeight: "5rem" }}>
              {emoji}
            </Typography>
          )}
          <Typography variant="Headline_3">{title}</Typography>
          <div>{children}</div>
        </div>
        <div css={buttonAreaStyle}>
          {variant === "default" && <DefaultActions closeButtonProps={closeButtonProps} />}
          {variant === "reaction" && <ReactionActions confirmButtonProps={confirmButtonProps} />}
          {variant === "choice" && (
            <ChoiceActions confirmButtonProps={confirmButtonProps} cancelButtonProps={cancelButtonProps} />
          )}
        </div>
      </div>
    </PopupDimmer>
  );
};

export default Popup;
