import type { Theme, Interpolation } from "@emotion/react";
import { css } from "@emotion/react";

import type { ButtonColor, ButtonSize, ButtonVariant } from ".";

type ButtonStylePresetType = {
  default?: string;
  hover?: string;
  active?: string;
  color?: string;
  shadow?: string;
};

type ButtonFontType = `$form${"1" | "2" | "3"}`;

const fontStyle: Record<ButtonFontType, Interpolation<Theme>> = {
  $form1: css`
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  $form2: css`
    font-size: 0.875rem;
    line-height: 1rem;
  `,
  $form3: css`
    font-size: 0.75rem;
    line-height: 1rem;
  `,
};

export const buttonWrapperStyle: Interpolation<Theme> = css`
  /* IE11 */
  display: table;

  position: relative;

  width: fit-content;
`;

export const commonButtonStyle: Interpolation<Theme> = ({ colors }) => css`
  all: initial;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  border: none;
  border-radius: 0.25rem;

  transition: 0.2s all;

  -webkit-tap-highlight-color: transparent;

  cursor: pointer;

  font-family: "Spoqa Han Sans", "SDGothicNeo1", sans-serif;

  overflow: hidden;

  :disabled {
    color: ${colors.$base_gray4};
    box-shadow: none;

    pointer-events: none;
  }
`;

export const getButtonStylePreset = ({ colors, effects }: Theme): Record<ButtonColor, ButtonStylePresetType> => ({
  blue: {
    default: colors.$brand_primary,
    hover: colors.$brand_light,
    active: colors.$brand_dark,
    color: colors.$base_white,
    shadow: effects.component.$blue_32,
  },
  navy: {
    default: colors.$base_primary,
    hover: colors.$base_light,
    active: colors.$base_dark,
    color: colors.$base_white,
    shadow: effects.component.$navy_32,
  },
  red: {
    default: colors.$warning_primary,
    hover: colors.$warning_light,
    active: colors.$warning_dark,
    color: colors.$base_white,
    shadow: effects.component.$red_32,
  },
  white: {
    default: colors.$base_white,
    hover: colors.$base_gray1,
    active: colors.$base_gray2,
    color: colors.$brand_primary,
    shadow: effects.component.$navy_32,
  },
  gray: {
    default: colors.$base_gray6,
  },
});

export const getVariantStyleMap = (stylePreset: ButtonStylePresetType): Record<ButtonVariant, Interpolation<Theme>> => {
  const primaryStyleMap: Interpolation<Theme> = (theme) => css`
    background: ${stylePreset.default};
    color: ${stylePreset.color};
    box-shadow: ${stylePreset.shadow};

    font-weight: bold;

    @media (hover: hover) and (pointer: fine) {
      :hover:not(:disabled) {
        background: ${stylePreset.hover};
      }
    }

    :active {
      background: ${stylePreset.active};
      box-shadow: none;
    }

    :disabled {
      background: ${theme.colors.$base_gray3};
    }
  `;

  const secondaryStyleMap: Interpolation<Theme> = (theme) => css`
    border: 1px solid ${theme.colors.$base_gray3};

    background: ${theme.colors.$base_white};
    color: ${stylePreset.default};
    box-shadow: ${theme.effects.component.$navy_8};

    @media (hover: hover) and (pointer: fine) {
      :hover:not(:disabled) {
        background: ${theme.colors.$base_gray1};
      }
    }

    :active {
      background: ${theme.colors.$base_gray2};
      box-shadow: none;
    }

    :disabled {
      background: ${theme.colors.$base_gray2};
    }
  `;

  const textStyleMap: Interpolation<Theme> = css`
    gap: 0.25rem;

    background: transparent;
    color: ${stylePreset.default};
    box-shadow: none;
  `;

  return {
    primary: primaryStyleMap,
    secondary: secondaryStyleMap,
    tiny: secondaryStyleMap,
    text: textStyleMap,
  };
};

export const sizeStyleMap: Record<ButtonSize, Interpolation<Theme>> = {
  56: [
    fontStyle.$form1,
    css`
      /* 
      padding: 1rem;
      gap: 0.5rem;
      */

      /* IE11 */
      padding: 1rem 0.75rem;

      > * {
        margin: 0 0.25rem;
      }
    `,
  ],
  48: [
    fontStyle.$form2,
    css`
      padding: 1rem 0.875rem;

      > * {
        margin: 0 0.125rem;
      }
    `,
  ],
  40: [
    fontStyle.$form3,
    css`
      padding: 0.75rem 0.625rem;

      > * {
        margin: 0 0.125rem;
      }
    `,
  ],
  32: [
    fontStyle.$form3,
    css`
      padding: 0.5rem 0.625rem;

      > * {
        margin: 0 0.125rem;
      }
    `,
  ],
};

export const iconOnlyExceptionStyle = css`
  padding: 0.75rem;
`;

export const loaderWrapperStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  pointer-events: none;
`;
