import type { FC } from "react";
import { useMemo } from "react";

import { InfoInformationIcon, InfoCautionIcon, InfoWarningIcon } from "@PRNDcompany/icon";
import type { IconType } from "@PRNDcompany/icon";
import Typography from "@PRNDcompany/typography";

import { contentStyle, getBoxStyle, iconColorMap, iconWrapperStyle, titleColorMap, titleStyle } from "./styles";

export type InformationType = "default" | "default2" | "highlight" | "caution" | "warning";

type InformationProps = {
  type: InformationType;
  icon?: IconType;
  title?: string;
};

const iconMap: Record<InformationType, IconType> = {
  default: InfoInformationIcon,
  default2: InfoInformationIcon,
  highlight: InfoInformationIcon,
  caution: InfoCautionIcon,
  warning: InfoWarningIcon,
};

const Information: FC<InformationProps> = ({ type, icon, title, children }) => {
  const boxStyle = useMemo(() => getBoxStyle(type), [type]);
  const Icon = icon || iconMap[type];

  return (
    <div css={boxStyle}>
      <div css={iconWrapperStyle}>
        <Icon size={16} color={iconColorMap[type]} />
      </div>
      {title && (
        <div css={titleStyle}>
          <Typography variant="Subtitle_3" color={titleColorMap[type]}>
            {title}
          </Typography>
        </div>
      )}
      {children && <div css={contentStyle}>{children}</div>}
    </div>
  );
};

export default Information;
