import React from "react";

import colors from "@PRNDcompany/colors";

import * as svgs from "../svgs";

export type IconProps = {
  name: keyof typeof svgs;
  color?: keyof typeof colors;
  size?: 24 | 16 | "original";
  children?: never;
};

const Icon: React.FC<IconProps> = ({ name, color = "$base_primary", size = "original" }) => {
  const IconSVG = svgs[name];
  return (
    <IconSVG
      fill={colors[color]}
      stroke={colors[color]}
      strokeWidth={0}
      {...(size === "original" ? {} : { width: size, height: size })}
    />
  );
};

export default Icon;
