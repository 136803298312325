import React from "react";

import { css } from "@emotion/react";

import { Icon } from "..";
import { IconProps } from "../Icon";

export type IconButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
} & IconProps;

const wrapper = css`
  all: unset;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;

  width: 3rem;
  height: 3rem;

  cursor: pointer;

  background: transparent;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  outline: none;
  font: inherit;
`;

const IconButton: React.VFC<IconButtonProps> = ({ onClick, ...iconProps }) => {
  return (
    <button css={wrapper} onClick={onClick}>
      <Icon {...iconProps} />
    </button>
  );
};

export default IconButton;
