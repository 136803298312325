import { Dispatch, SetStateAction, useEffect, useState } from "react";

const DEFAULT_DURATION = 5000;

export const toastTypes = {
  Default: "default",
  Success: "success",
  Fail: "error",
} as const;

export type ToastType = typeof toastTypes[keyof typeof toastTypes];

export type ToastProps = {
  id: string;
  type: ToastType;
  message: string;
  duration: number;
};

const toastStore: ToastProps[] = [];
const listeners: Dispatch<SetStateAction<ToastProps[]>>[] = [];

const setToastStore = (newStore: ToastProps[]) => {
  toastStore.splice(0, toastStore.length, ...newStore);
  listeners.map((listener) => listener(newStore));
};

const removeToast = (id: ToastProps["id"]): void => {
  setToastStore(toastStore.filter((toast) => toast.id !== id));
};
const createToast = (message: string, type: ToastType = toastTypes.Default, duration: number = DEFAULT_DURATION) => {
  const id = Math.random().toString().slice(2, 10);
  const toast: ToastProps = { id, message, type, duration: duration };
  setToastStore([...toastStore, toast]);
  setTimeout(removeToast, duration, id);
};

export const toast = {
  create: createToast,
  default: (message: string, duration?: number) => createToast(message, toastTypes.Default, duration),
  success: (message: string, duration?: number) => createToast(message, toastTypes.Success, duration),
  fail: (message: string, duration?: number) => createToast(message, toastTypes.Fail, duration),
};

export const useToastStore = () => {
  const [store, setStore] = useState<ToastProps[]>(toastStore);

  useEffect(() => {
    listeners.push(setStore);
    return () => {
      listeners.splice(listeners.indexOf(setStore), 1);
    };
  }, []);

  return { store };
};
