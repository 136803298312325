import { useEffect, useState } from "react";

import type { Interpolation, Theme } from "@emotion/react";
import { css } from "@emotion/react";
import type { IconType } from "@PRNDcompany/icon";
import { ConfirmPassIcon, ConfirmFailIcon, InfoInformationIcon } from "@PRNDcompany/icon";
import Typography from "@PRNDcompany/typography";

import type { ToastType, ToastProps } from "./core";
import { toastTypes } from "./core";

const TOAST_TRANSITION_TIME = 300;

const toastWrapperStyle: Interpolation<Theme> = css`
  transition: all ${TOAST_TRANSITION_TIME}ms ease-in-out;
`;

const toastComponentStyle: Interpolation<Theme> = (theme) => css`
  box-sizing: border-box;
  margin: 0.5rem;
  padding: 0.5rem 0.75rem;

  border-radius: 0.25rem;
  box-shadow: ${theme.shadows.layer.$level_8};

  display: flex;
  flex-direction: row;

  color: ${theme.colors.$base_white};

  white-space: pre;
`;

const toastHidingStyle: Interpolation<Theme> = css`
  opacity: 0;
`;

const iconWrapperStyle: Interpolation<Theme> = css`
  margin-top: 0.375rem;
  margin-right: 0.25rem;
`;

const defaultToastStyle: Interpolation<Theme> = (theme) => css`
  background: ${theme.colors.$base_primary};
`;
const successToastStyle: Interpolation<Theme> = (theme) => css`
  background: ${theme.colors.$success_primary};
`;
const failToastStyle: Interpolation<Theme> = (theme) => css`
  background: ${theme.colors.$warning_primary};
`;

const iconMap: Record<ToastType, IconType> = {
  [toastTypes.Default]: InfoInformationIcon,
  [toastTypes.Success]: ConfirmPassIcon,
  [toastTypes.Fail]: ConfirmFailIcon,
};

const styleMap: Record<ToastType, Interpolation<Theme>> = {
  [toastTypes.Default]: defaultToastStyle,
  [toastTypes.Success]: successToastStyle,
  [toastTypes.Fail]: failToastStyle,
};

export const _ToastComponent: React.FC<ToastProps> = ({ type, message, duration: _ = 5000 }) => {
  const Icon = iconMap[type];

  return (
    <div css={[toastComponentStyle, styleMap[type]]}>
      <div css={iconWrapperStyle}>
        <Icon size={16} />
      </div>
      <Typography variant="Subtitle_2">{message}</Typography>
    </div>
  );
};

const ToastContainer: React.FC<ToastProps> = (props) => {
  const { duration } = props;

  const [init, setInit] = useState(true);
  const [out, setOut] = useState(false);

  useEffect(() => {
    setTimeout(() => setInit(false));
    const timer = setTimeout(() => setOut(true), duration - TOAST_TRANSITION_TIME);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div css={[toastWrapperStyle, (init || out) && toastHidingStyle]}>
      <_ToastComponent {...props} />
    </div>
  );
};

export default ToastContainer;
