import colors from "../colors";

import type { ShadowScheme } from "./types";

/** @deprecated Use `box-shadow: ${theme.effects.*};` instead */
const shadows: Readonly<ShadowScheme> = Object.freeze({
  layer: {
    $level_0: `0px 0px 0px ${colors.$base_8};`,
    $level_1: `0px 1px 2px ${colors.$base_8};`,
    $level_2: `0px 2px 4px ${colors.$base_8};`,
    $level_4: `0px 4px 8px ${colors.$base_8};`,
    $level_8: `0px 8px 16px ${colors.$base_8};`,
    $level_16: `0px 16px 32px ${colors.$base_8};`,
    $level_32: `0px 32px 64px ${colors.$base_8};`,
  },
  component: {
    $navy_32: `0px 2px 4px ${colors.$base_32};`,
    $navy_8: `0px 2px 4px ${colors.$base_8};`,
    $blue_32: `0px 2px 4px ${colors.$brand_32};`,
    $blue_16: `0px 2px 4px ${colors.$brand_16};`,
    $red_32: `0px 2px 4px ${colors.$warning_32};`,
    $red_16: `0px 2px 4px ${colors.$warning_16};`,
    $green_32: `0px 2px 4px ${colors.$success_32};`,
    $yellow_32: `0px 2px 4px ${colors.$caution_32};`,
  },
});

export default shadows;
