import type { ComponentProps, VFC } from "react";

import { css } from "@emotion/react";
import Button from "@PRNDcompany/button";
import { ConfirmPassIcon } from "@PRNDcompany/icon";

// TODO: Consider separating onClick from CustomizableButtonProps and let this value focus only on customization
type CustomizableButtonProps = Partial<Omit<ComponentProps<typeof Button>, "variant" | "size" | "color" | "fullWidth">>;

export type DefaultActionsProps = {
  confirmButtonProps?: never;
  cancelButtonProps?: never;
  closeButtonProps?: CustomizableButtonProps & { disabled?: never };
};

export const DefaultActions: VFC<DefaultActionsProps> = ({
  closeButtonProps: { children = "닫기", ...restCloseAction } = {},
}) => (
  <div css={{ display: "flex", justifyContent: "flex-end" }}>
    <Button variant="text" size={56} color="blue" {...restCloseAction}>
      {children}
    </Button>
  </div>
);

export type ReactionActionsProps = {
  confirmButtonProps?: CustomizableButtonProps;
  cancelButtonProps?: never;
  closeButtonProps?: never;
};

export const ReactionActions: VFC<ReactionActionsProps> = ({
  confirmButtonProps: { children = "확인", ...restConfirmAction } = {},
}) => (
  <Button variant="primary" size={56} color="blue" icon={ConfirmPassIcon} fullWidth {...restConfirmAction}>
    {children}
  </Button>
);

export type ChoiceActionsProps = {
  confirmButtonProps?: CustomizableButtonProps;
  cancelButtonProps?: CustomizableButtonProps & { disabled?: never };
  closeButtonProps?: never;
};

const choiceActionsStyle = css`
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const ChoiceActions: VFC<ChoiceActionsProps> = ({
  confirmButtonProps: { children: confirmChildren = "확인", ...restConfirmAction } = {},
  cancelButtonProps: { children: cancelChildren = "취소", ...restCancelAction } = {},
}) => (
  <div css={choiceActionsStyle}>
    <Button variant="text" size={56} color="blue" {...restCancelAction}>
      {cancelChildren}
    </Button>
    <Button variant="primary" size={56} color="blue" icon={ConfirmPassIcon} {...restConfirmAction}>
      {confirmChildren}
    </Button>
  </div>
);

// TODO-ui/popup: option variant
