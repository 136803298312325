import type { Interpolation, Theme } from "@emotion/react";
import { css, Global } from "@emotion/react";
import "spoqa-han-sans/css/SpoqaHanSans-kr.css";

const globalStyle: Interpolation<Theme> = ({ colors }) => css`
  html,
  body,
  * {
    font-family: "Spoqa Han Sans", "SDGothicNeo1", sans-serif;
  }

  body {
    color: ${colors.$base_primary};
  }
`;

const GlobalStyle: React.FC = () => <Global styles={globalStyle} />;

export default GlobalStyle;
