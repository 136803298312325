import type { FontScheme } from "./types";

const fonts: Readonly<FontScheme> = Object.freeze({
  Headline_1: {
    weight: 700,
    size: "2.5rem",
    lineHeight: "4rem",
  },
  Headline_2: {
    weight: 700,
    size: "2rem",
    lineHeight: "3.25rem",
  },
  Headline_3: {
    weight: 700,
    size: "1.5rem",
    lineHeight: "2.5rem",
  },
  Headline_3_L: {
    weight: 300,
    size: "1.5rem",
    lineHeight: "2.5rem",
  },
  Subtitle_1: {
    weight: 700,
    size: "1.25rem",
    lineHeight: "2rem",
  },
  Subtitle_2: {
    weight: 700,
    size: "1rem",
    lineHeight: "1.75rem",
  },
  Subtitle_3: {
    weight: 700,
    size: "0.875rem",
    lineHeight: "1.5rem",
  },
  Subtitle_4: {
    weight: 700,
    size: "0.75rem",
    lineHeight: "1.25rem",
  },
  Body_1: {
    weight: 400,
    size: "1rem",
    lineHeight: "1.75rem",
  },
  Body_2: {
    weight: 400,
    size: "0.875rem",
    lineHeight: "1.5rem",
  },
  Caption_1: {
    weight: 400,
    size: "0.75rem",
    lineHeight: "1.25rem",
  },
});

export default fonts;
