import colors from "../colors";

import type { EffectScheme } from "./types";

const effects: Readonly<EffectScheme> = Object.freeze({
  layer: {
    $level_0: `0 0 0 ${colors.$base_8}`,
    $level_1: `0 0.0625rem 0.125rem ${colors.$base_8}`,
    $level_2: `0 0.125rem 0.25rem ${colors.$base_8}`,
    $level_4: `0 0.25rem 0.5rem ${colors.$base_8}`,
    $level_8: `0 0.5rem 1rem ${colors.$base_8}`,
    $level_16: `0 1rem 2rem ${colors.$base_8}`,
    $level_32: `0 2rem 4rem ${colors.$base_8}`,
  },
  component: {
    $navy_32: `0 0.125rem 0.25rem ${colors.$base_32}`,
    $navy_8: `0 0.125rem 0.25rem ${colors.$base_8}`,
    $blue_32: `0 0.125rem 0.25rem ${colors.$brand_32}`,
    $blue_16: `0 0.125rem 0.25rem ${colors.$brand_16}`,
    $red_32: `0 0.125rem 0.25rem ${colors.$warning_32}`,
    $red_16: `0 0.125rem 0.25rem ${colors.$warning_16}`,
    $green_32: `0 0.125rem 0.25rem ${colors.$success_32}`,
    $yellow_32: `0 0.125rem 0.25rem ${colors.$caution_32}`,
  },
});

export default effects;
