import type { FC } from "react";

import type { Interpolation, Theme } from "@emotion/react";
import { css } from "@emotion/react";
import { useTransition, animated } from "@react-spring/web";
import { createPortal } from "react-dom";

export type DimmerProps = { open: boolean };

const POPUP_Z_INDEX = 10000;

const dimmerStyle: Interpolation<Theme> = ({ colors }) => css`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 1rem;

  background: ${colors.$base_16};

  z-index: ${POPUP_Z_INDEX};
`;

const PopupDimmer: FC<DimmerProps> = ({ open, children }) => {
  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: open,
  });

  return createPortal(
    transitions(
      ({ opacity }, transitionOpen) =>
        transitionOpen && (
          <animated.div id="popup-layer" style={{ opacity }} css={dimmerStyle}>
            {children}
          </animated.div>
        )
    ),
    document.body
  );
};

export default PopupDimmer;
