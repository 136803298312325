import { ThemeProvider } from "@emotion/react";
import theme, { DesignTokens } from "@PRNDcompany/design-token";

import GlobalStyle from "../GlobalStyle";

declare module "@emotion/react" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends DesignTokens {}
}

/*  In HeydealerThemeProvider, ancestorTheme always get higher priority
    Hence, every component package implemented with this can assure existence of theme 
    without overriding project theme (which will be provided as ancestorTheme) if exists  */
const HeydealerThemeProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={(ancestorTheme) => ({ ...theme, ...ancestorTheme })}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

export const withHeydealerTheme: <P extends Record<string, unknown>>(Component: React.ComponentType<P>) => React.FC<P> =
  (Component) => (props) => {
    return (
      <HeydealerThemeProvider>
        <Component {...props} />
      </HeydealerThemeProvider>
    );
  };

export default HeydealerThemeProvider;
