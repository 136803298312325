import type { ComponentProps } from "react";
import { useMemo } from "react";

import type { Theme, Interpolation } from "@emotion/react";
import { useTheme } from "@emotion/react";
import type { DesignTokens } from "@PRNDcompany/design-token";
import type { IconType } from "@PRNDcompany/icon";
import { ArrowDownIcon, ArrowLeftIcon, ArrowUpIcon, ArrowRightIcon } from "@PRNDcompany/icon";
import { SyncLoader } from "react-spinners";

import {
  commonButtonStyle,
  getVariantStyleMap,
  sizeStyleMap,
  getButtonStylePreset,
  loaderWrapperStyle,
  buttonWrapperStyle,
  iconOnlyExceptionStyle,
} from "./styles";

export type ButtonVariant = "primary" | "secondary" | "text" | "tiny";
export type ButtonSize = 56 | 48 | 40 | 32;
export type ButtonColor = "blue" | "navy" | "red" | "white" | "gray";

type ArrowType = "up" | "down" | "left" | "right";

type VariantProps =
  | { variant?: "primary"; color?: Exclude<ButtonColor, "gray">; size?: Exclude<ButtonSize, 32> }
  | { variant?: "secondary"; color?: Exclude<ButtonColor, "white">; size?: Exclude<ButtonSize, 32> }
  | { variant?: "tiny"; color?: Exclude<ButtonColor, "white">; size?: 32; fullWidth?: false }
  | { variant?: "text"; color?: Exclude<ButtonColor, "navy">; size?: 56 | 48; disabled?: false; fullWidth?: false };

type ButtonProps = Pick<ComponentProps<"button">, "onClick"> & {
  icon?: IconType | false;
  iconColor?: keyof DesignTokens["colors"];
  arrow?: ArrowType;
  arrowColor?: keyof DesignTokens["colors"];
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  customCSS?: Interpolation<Theme>;
} & VariantProps;

const defaultSizeMap: { [key in ButtonVariant]: ButtonSize } = {
  primary: 56,
  secondary: 56,
  tiny: 32,
  text: 56,
};

const rightArrowIconMap: Record<ArrowType, IconType> = {
  up: ArrowUpIcon,
  down: ArrowDownIcon,
  left: ArrowLeftIcon,
  right: ArrowRightIcon,
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  icon: Icon,
  iconColor,
  children,
  arrow,
  arrowColor,
  loading = false,
  disabled,
  fullWidth,
  customCSS,
  variant = "primary",
  color = "blue",
  size = defaultSizeMap[variant],
}) => {
  const theme = useTheme();
  const buttonStylePreset = useMemo(() => getButtonStylePreset(theme), [theme]);
  const variantStyle = useMemo(() => getVariantStyleMap(buttonStylePreset[color])[variant], [color, variant]);

  const RightArrow = arrow ? rightArrowIconMap[arrow] : undefined;

  const iconOnly = Icon && !children && !arrow;
  const startIconSize: 24 | 16 = (() => {
    if (size === 56) return 24;
    if (size === 48 && iconOnly) return 24;

    return 16;
  })();

  return (
    <div css={[buttonWrapperStyle, fullWidth && { width: "100%" }]}>
      <button
        disabled={loading || disabled}
        css={[
          commonButtonStyle,
          fullWidth && { width: "100%" },
          sizeStyleMap[size],
          variantStyle,
          size === 48 && iconOnly && iconOnlyExceptionStyle,
          customCSS,
          loading && { opacity: 0 },
        ]}
        onClick={onClick}
      >
        {Icon && <Icon size={startIconSize} color={iconColor} />}
        {children && <div>{children}</div>}
        {RightArrow && <RightArrow size={16} color={arrowColor} />}
      </button>
      {loading && (
        <div css={[commonButtonStyle, variantStyle, customCSS, loaderWrapperStyle]}>
          <SyncLoader color="currentColor" size={6} />
        </div>
      )}
    </div>
  );
};

export default Button;
