import type { Interpolation, Theme } from "@emotion/react";
import { css } from "@emotion/react";

export const wrapperStyle: Interpolation<Theme> = ({ effects }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-shadow: ${effects.layer.$level_32};

  white-space: pre-wrap;
`;

export const contentAreaStyle: Interpolation<Theme> = ({ colors, fonts }) => css`
  display: flex;
  flex-flow: column nowrap;

  padding: 2rem;

  background: ${colors.$base_white};

  font-size: ${fonts.Body_1.size};
  line-height: ${fonts.Body_1.lineHeight};
  font-weight: ${fonts.Body_1.weight};

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const buttonAreaStyle: Interpolation<Theme> = ({ colors }) => css`
  padding: 1rem;

  background: ${colors.$base_white};
`;
