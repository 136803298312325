import React from "react";

import type { Theme, Interpolation } from "@emotion/react";
import { jsx, css } from "@emotion/react";

export const variants = [
  "Headline_1",
  "Headline_2",
  "Headline_3",
  "Headline_3_L",
  "Subtitle_1",
  "Subtitle_2",
  "Subtitle_3",
  "Subtitle_4",
  "Body_1",
  "Body_2",
  "Caption_1",
] as const;
type VariantType = typeof variants[number];

export type TypographyProps = {
  variant: VariantType;
  color?: keyof Theme["colors"];
  customCSS?: Interpolation<Theme>;
  inline?: boolean;
  ellipsis?: boolean;
};

const Typography: React.FC<TypographyProps> = ({ variant, color, customCSS, inline, ellipsis, children }) => {
  const tagMap: Record<VariantType, string> = {
    Headline_1: "h1",
    Headline_2: "h2",
    Headline_3: "h3",
    Headline_3_L: "h3",
    Subtitle_1: "h4",
    Subtitle_2: "h5",
    Subtitle_3: "h6",
    Subtitle_4: "h6",
    Body_1: "p",
    Body_2: "p",
    Caption_1: "p",
  };

  const common = (theme: Theme) => css`
    display: ${inline ? "inline-block" : "block"};
    color: ${color ? theme.colors[color] : "inherit"};
    font-family: "Spoqa Han Sans", "SDGothicNeo1", "sans-serif";
    margin: 0;
  `;

  const ellipsisStyle = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;

  const getVariantStyle =
    (variant: VariantType): Interpolation<Theme> =>
    (theme) =>
      css`
        font-size: ${theme.fonts[variant].size};
        font-weight: ${theme.fonts[variant].weight};
        line-height: ${theme.fonts[variant].lineHeight};
      `;

  return jsx(
    tagMap[variant] || "span",
    { css: [common, getVariantStyle(variant), customCSS, ellipsis && ellipsisStyle] },
    children
  );
};

export default Typography;
