import colors from "./colors";
import effects from "./effects";
import fonts from "./fonts";
import shadows from "./shadows";

const designTokens: DesignTokens = { colors, fonts, effects, shadows };

export interface DesignTokens {
  colors: typeof colors;
  effects: typeof effects;
  fonts: typeof fonts;
  /** @deprecated Use `box-shadow: ${theme.effects.*};` instead */
  shadows: typeof shadows;
}

export default designTokens;
