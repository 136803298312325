import React, { ComponentProps, ReactNode } from "react";
import { css, SerializedStyles } from "@emotion/react";
import colors from "@PRNDcompany/colors";

export type buttonVariant = "primary" | "secondary" | "text";

export type buttonSize = "big" | "medium" | "small" | "tiny";
export type buttonColor = "blue" | "gray";
import { SyncLoader } from "react-spinners";

export interface Props {
  variant?: buttonVariant;
  color?: buttonColor;
  size?: buttonSize;
  css?: SerializedStyles;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  isLoading?: boolean;
  loaderColor?: string;
}

const Button: React.FC<Props & ComponentProps<"button">> = ({
  variant = "primary",
  color = "blue",
  size = "big",
  startIcon,
  endIcon,
  children,
  isLoading = false,
  loaderColor = colors.$base_white,
  ...otherProps
}) => {
  const button = css`
    font-family: "Spoqa Han Sans Neo", "SDGothicNeo1", "sans-serif";
    border-radius: 0.25rem;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    transition: 0.2s all;
    -webkit-tap-highlight-color: transparent;
    position: relative;
  `;

  const childrenCss = css`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  /** 우선순위1 **/
  const disabledCss = css`
    border: none;
    box-shadow: none;
    background-color: ${colors.$base_gray2};
    font-weight: bold;
    color: ${colors.$base_gray4};
    @media (hover: hover) {
      &:hover {
        cursor: auto;
        background-color: ${colors.$base_gray2};
      }
    }
    &:active {
      background-color: ${colors.$base_gray2};
    }
  `;

  /** 우선순위2 **/
  const loadingChildCss = css`
    opacity: 0;
  `;

  const loadingButtonCss = css`
    cursor: auto;
  `;

  /** 우선순위3 **/
  const variantMap: Record<buttonVariant, SerializedStyles> = {
    primary: css`
      border: none;
      color: ${colors.$base_white};
      font-weight: bold;
    `,

    secondary: css`
      background-color: ${colors.$base_white};
      box-shadow: 0 0.125rem 0.25rem ${colors.$base_8};
      border: 1px solid ${colors.$base_gray3};
      @media (hover: hover) {
        &:hover {
          background-color: ${colors.$base_gray1};
        }
      }
      &:active {
        background-color: ${colors.$base_gray2};
      }
    `,

    text: css`
      background: none;
      border: none;
      box-shadow: none;
      @media (hover: hover) {
        &:hover {
          background-color: transparent;
        }
      }
      &:active {
        background-color: transparent;
      }
    `,
  };

  /** 우선순위4 **/
  const colorMap: Record<buttonColor, SerializedStyles> = {
    blue: css`
      background-color: ${colors.$brand_primary};
      color: ${colors.$brand_primary};
      box-shadow: 0 0.125rem 0.25rem ${colors.$brand_32};
      @media (hover: hover) {
        &:hover {
          background-color: ${colors.$brand_light};
        }
      }
      &:active {
        background-color: ${colors.$brand_dark};
      }
    `,
    gray: css`
      color: ${colors.$base_gray6};
    `,
  };

  /** 우선순위5 **/
  const sizeMap: Record<buttonSize, SerializedStyles> = {
    big: css`
      font-size: 1rem;
      line-height: 1.5rem;
      height: 3.5rem;
      padding: 1rem;
    `,
    medium: css`
      font-size: 0.875rem;
      line-height: 1rem;
      height: 3rem;
      padding: 1rem;
    `,
    small: css`
      font-size: 0.75rem;
      line-height: 1rem;
      height: 2.5rem;
      padding: 0.75rem;
    `,
    tiny: css`
      font-size: 0.75rem;
      line-height: 1rem;
      height: 2rem;
      padding: 0.5rem 0.75rem;
    `,
  };

  const getChildren = () => {
    return (
      <div css={[childrenCss, isLoading && loadingChildCss]}>
        {startIcon && (
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-right: 8px;
            `}
          >
            {startIcon}
          </div>
        )}
        {children}
        {endIcon && (
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-left: 8px;
            `}
          >
            {endIcon}
          </div>
        )}
      </div>
    );
  };

  return (
    <button
      {...otherProps}
      disabled={isLoading || otherProps.disabled}
      css={[
        button,
        sizeMap[size],
        colorMap[color],
        variantMap[variant],
        isLoading && loadingButtonCss,
        otherProps.disabled && disabledCss,
        otherProps.css,
      ]}
    >
      {getChildren()}
      {isLoading && (
        <div
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <SyncLoader color={loaderColor} loading={true} size={6} />
        </div>
      )}
    </button>
  );
};

export default Button;
