import { css, Interpolation, Theme, useTheme } from "@emotion/react";

import { IconComponentProps } from "../types";

type IconWrapperProps = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
} & IconComponentProps;

const buttonStyle: Interpolation<Theme> = css`
  all: unset;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;

  width: 3rem;
  height: 3rem;

  cursor: pointer;

  background: transparent;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  outline: none;
  font: inherit;
`;

const IconWrapper: React.FC<IconWrapperProps> = ({ Icon, color, size, button, onClick }) => {
  const theme = useTheme();

  const defaultStyle: Interpolation<Theme> = css`
    width: ${size / 16}rem;
    height: ${size / 16}rem;
    display: flex;
  `;

  return (
    <div
      css={[defaultStyle, button && buttonStyle, { color: (color && theme.colors[color]) || "inherit" }]}
      onClick={onClick}
    >
      <Icon width={`${size / 16}rem`} height={`${size / 16}rem`} />
    </div>
  );
};

export default IconWrapper;
